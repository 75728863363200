import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import '../styles/app.scss';

const WeRecommend = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    console.log(post.yoast_json_ld[0].wordpress__graph[0].description);

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section centersec smallestwdt nopaddbottsec">
                <div className="container">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.we_recommend_heading,
                        }}
                    />
                    <p
                        className="lastitem biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.we_recommend_description,
                        }}
                    />

                    <img
                        className="schoolsline"
                        src={LineFull}
                        alt="linefull"
                    />
                </div>

                <div className="werecommwrap">
                    {post.acf.we_recommend_logos.map((logo, i) => (
                        <div className="recommimg" key={i}>
                            <a
                                href={logo.wecommend_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={
                                        logo.recommend_logo.localFile
                                            .childImageSharp.fluid.src
                                    }
                                    alt="werec"
                                />
                            </a>
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default WeRecommend;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
            acf {
                we_recommend_heading
                we_recommend_description
                we_recommend_logos {
                    recommend_logo {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                    wecommend_url
                }
            }
        }
    }
`;
